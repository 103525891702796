import { render, staticRenderFns } from "./ShoppingCart.vue?vue&type=template&id=e2d010ea&scoped=true&"
import script from "./ShoppingCart.vue?vue&type=script&lang=js&"
export * from "./ShoppingCart.vue?vue&type=script&lang=js&"
import style0 from "./ShoppingCart.vue?vue&type=style&index=0&id=e2d010ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2d010ea",
  null
  
)

export default component.exports